/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../common/constants';
import apiV1 from '../../../../services/axiosConfig/apiV1';

const initialState = {
    cronOptionsList: [],
    cronJobsData: [],
    statusOfCronList: FETCH_STATUS.INIT,
    statusOfRunJob: FETCH_STATUS.INIT,
    statusOfCronJobHistory: FETCH_STATUS.INIT,
    cronJobHistory: [],
    runJobResult: {},
    runDetails: [],
    statusOfRunDetails: FETCH_STATUS.INIT,
};

export const getCronOptionsList = createAsyncThunk(
    'cronJobsSlice/getLogsCSV',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(`bfg/olp/jobList`);

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const runJob = createAsyncThunk(
    'cronJobsSlice/runJob',
    async (cronValue, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(`bfg/olp/runJob`, 'post', {
                action: cronValue,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCornJobsInfo = createAsyncThunk(
    'cronJobsSlice/getCornJobsInfo',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(`bfg/olp`);

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCronJobHistory = createAsyncThunk(
    'cronJobsSlice/getCronJobHistory',
    async (id, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(`bfg/olp/byId/${id}`);

            return { response: response.data, id: id };
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getRunDetails = createAsyncThunk(
    'cronJobsSlice/getRunDetails',
    async ({ id, jobId }, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                `bfg/olp/byId/${id}/${jobId}`,
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const cronJobsSlice = createSlice({
    name: 'cronJobsSlice',
    initialState,
    reducers: {
        resetRunJobStatus(state) {
            return {
                ...state,
                statusOfRunJob: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCronOptionsList.pending, (state) => {
                state.statusOfCronList = FETCH_STATUS.FETCHING;
            })
            .addCase(getCronOptionsList.fulfilled, (state, action) => {
                state.statusOfCronList = FETCH_STATUS.SUCCESS;
                state.cronOptionsList = action?.payload?.data;
            })
            .addCase(getCronOptionsList.rejected, (state) => {
                state.statusOfCronList = FETCH_STATUS.ERROR;
            })
            .addCase(runJob.pending, (state) => {
                state.statusOfRunJob = FETCH_STATUS.FETCHING;
            })
            .addCase(runJob.fulfilled, (state, action) => {
                state.statusOfRunJob = FETCH_STATUS.SUCCESS;
                state.runJobResult = action?.payload;
            })
            .addCase(runJob.rejected, (state, action) => {
                state.statusOfRunJob = FETCH_STATUS.ERROR;
                state.runJobResult = action?.payload;
            })
            .addCase(getCornJobsInfo.pending, (state) => {
                state.statusOfCronList = FETCH_STATUS.FETCHING;
            })
            .addCase(getCornJobsInfo.fulfilled, (state, action) => {
                state.statusOfCronList = FETCH_STATUS.SUCCESS;
                state.cronJobsData = action?.payload?.data;
            })
            .addCase(getCornJobsInfo.rejected, (state) => {
                state.statusOfCronList = FETCH_STATUS.ERROR;
            })
            .addCase(getCronJobHistory.pending, (state, action) => {
                state[`statusOf${action?.meta?.arg}`] = FETCH_STATUS.FETCHING;
            })
            .addCase(getCronJobHistory.fulfilled, (state, action) => {
                state[`statusOf${action?.payload.id}`] = FETCH_STATUS.SUCCESS;
                state[action?.payload.id] = action?.payload?.response.data;
            })
            .addCase(getCronJobHistory.rejected, (state, action) => {
                state[`statusOf${action?.meta?.arg}`] = FETCH_STATUS.ERROR;
            })
            .addCase(getRunDetails.pending, (state) => {
                state.statusOfRunDetails = FETCH_STATUS.FETCHING;
            })
            .addCase(getRunDetails.fulfilled, (state, action) => {
                state.statusOfRunDetails = FETCH_STATUS.SUCCESS;
                state.runDetails = action?.payload?.data;
            })
            .addCase(getRunDetails.rejected, (state) => {
                state.statusOfRunDetails = FETCH_STATUS.ERROR;
            });
    },
});

export const { resetRunJobStatus } = cronJobsSlice.actions;

export default cronJobsSlice.reducer;
